import { useState } from 'react';

const useLoader = () => {
  const [loader, setLoader] = useState(false);

  const showLoader = (isVisible) => {
    setLoader(isVisible);
  };

  return {
    loader,
    showLoader,
  };
};

export default useLoader;
