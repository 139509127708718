import { useEffect, useState } from 'react';
import { Form, Modal, Button, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { VolunteersTable } from './VolunteersTable';
import { VolunteersForm } from './VolunteersForm';

import {
    getVolunteers, getLocations, getCategories,
    createVolunteer, updateVolunteer, deleteVolunteer,
    approveVolunteer, rejectVolunteer
} from '../../services/DataService';

export const Volunteers = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [dataSource, setDataSource] = useState([]);
    const [formVisible, setFormVisability] = useState(false);
    const [activeRecord, setActiveRecord] = useState(null);


    // TODO: move to global common state
    const [availableLocations, setLocations] = useState([]);
    const [availableCategories, setCategories] = useState([]);

    // Hooks
    useEffect(() => {
        const fetch = async () => {
            const [volunteers, locations, categories] = await Promise.all([
                getVolunteers(),
                getLocations(),
                getCategories()
            ]);

            setDataSource(volunteers);
            setLocations(locations);
            setCategories(categories);
        }

        fetch();
    }, []);

    // TODO: utilize somehow ?!
    const wrapRowUpdatesApply = (fn, { loadingMessage, successMessage, errorMessage }) => {
        return async (...args) => {
            const key = '_key_';
            try {
                message.loading({ key, content: loadingMessage });

                await fn(...args);

                message.success({ key, content: successMessage  });
            } catch {
                message.error({ key, content: errorMessage });
            }

            setDataSource(await getVolunteers());
        }
    }

    // Methods
    const openEditableForm = (record = null) => {
        form.resetFields();
        setActiveRecord(record);
        setFormVisability(true);
    }

    const onFormSubmit = async () => {
        try { await form.validateFields(); } catch { return };

        return wrapRowUpdatesApply(
            async () => {
                const formData = form.getFieldsValue();

                // Process settings obj
                formData.settings = JSON.stringify({
                    'categories_contacts': formData['settings.categories_contacts']
                });
                delete formData['settings.categories_contacts'];

                if (activeRecord?.id) {
                    await updateVolunteer(activeRecord.id, formData);
                } else {
                    await createVolunteer(formData);
                }

                setActiveRecord(null);
                setFormVisability(false);
            },
            {
                loadingMessage: 'Update in progress...',
                successMessage: 'Successfully updated',
                errorMessage: 'Something going wrong during update..'
            }
        )();
    };

    const deleteRecord = record => {
        Modal.confirm({
            title: 'Confirm',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure that you want to remove "${record.name}"`,
            onOk: () => wrapRowUpdatesApply(
                (record) => deleteVolunteer(record.id),
                {
                    loadingMessage: 'Removing in progress...',
                    successMessage: 'Successfully removed',
                    errorMessage: 'Something going wrong during removing..'
                }
            )(record)
        });
    }

    const approveRecord = wrapRowUpdatesApply(
        (record) => approveVolunteer(record.id),
        {
            loadingMessage: 'Approving in progress...',
            successMessage: 'Successfully approved',
            errorMessage: 'Something going wrong during approving..'
        }
    );

    const rejectRecord = wrapRowUpdatesApply(
        (record) => rejectVolunteer(record.id),
        {
            loadingMessage: 'Rejecting in progress...',
            successMessage: 'Successfully rejected',
            errorMessage: 'Something going wrong during rejecting..'
        }
    );

    return (
        <>
            <Button
                type="primary"
                style={{ marginBottom: 16 }}
                onClick={() => openEditableForm()}
            >
                {t('common.actions.create_new')}
            </Button>

            <VolunteersTable
                dataSource={dataSource.map(v => ({ ...v, key: v.id }))}
                onEdit={openEditableForm}
                onDelete={deleteRecord}
                onApprove={approveRecord}
                onReject={rejectRecord}/>

            <Modal
                width="80%"
                style={{ top: 20 }}
                onCancel={() => setFormVisability(false)}
                onOk={onFormSubmit}
                visible={formVisible}
                keyboard={false} // allow to press Escape button on form inputs without closing form
            >
                <VolunteersForm
                    formRef={form}
                    record={activeRecord}
                    locations={availableLocations}
                    categories={availableCategories}/>
            </Modal>
        </>
    )
}
