
import { useEffect, useState } from 'react';
import { Table, Input, Popconfirm, Form, Button, Space } from 'antd';
import { EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import './index.scss';

export const NEW_ROW_KEY = '__NEW__ROW__';

export const ActionsCell = ({
  record, mode, freeze,
  onEdit, onEditReject, onSave, onDelete, onAdd,
}) => {
  const { t } = useTranslation();

  if (mode === 'edit') {
    return (
      <Space>
        <Button
          type="primary"
          icon={<CheckOutlined />}
          onClick={() => onSave(record)}
        />

        <Button
          danger
          type="dashed"
          icon={<CloseOutlined />}
          onClick={() => onEditReject(record)}
        />
      </Space>
    );
  }

  return (
    <Space>
      <Button
        type="primary"
        icon={<EditOutlined />}
        title={t('categories.btns.edit')}
        onClick={() => onEdit(record)}
        disabled={!freeze}
      />

      <Button
        type="dashed"
        icon={<PlusOutlined />}
        title={t('categories.btns.add_sub')}
        onClick={() => onAdd(record)}
        disabled={!freeze}
      />

      <Popconfirm
        title={t('categories.confirm.title')}
        okText={t('categories.confirm.yes')}
        cancelText={t('categories.confirm.no')}
        onConfirm={() => onDelete(record)}
      >
        <Button
          danger
          type="dashed"
          title={t('categories.btns.delete')}
          icon={<DeleteOutlined />}
          disabled={!freeze}
        />
      </Popconfirm>
    </Space>
  );
}

export const EditableCell = ({
  editing, dataIndex, title,
  inputType, record, children,
  ...restProps
}) => {
  const { t } = useTranslation();

  if (editing) {
    return (
      <td {...restProps}>
        <Form.Item
          name={dataIndex}
          style={{ margin: 0, }}
          rules={[
            {
              required: true,
              message: t('categories.confirm.required_field'),
            },
          ]}
        >
          <Input />
        </Form.Item>
      </td>
    )
  }

  return (
    <td {...restProps}>
      {children}
    </td>
  );
};

export const CategoriesTable = ({ categories, onCreate, onUpdate, onDelete }) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setData(categories);
  }, [categories])

  const isEditing = (record) => record.key === editingKey;

  const onRowEdit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const onRowEditReject = (category) => {
    // Remove new row on edit reject
    if (category.key === NEW_ROW_KEY) {
      const parent = category.parent_ref;
      if (parent) {
        if (parent.children.length > 1) {
          parent.children = parent.children.slice(1);
        } else {
          delete parent.children;
        }

        setData([ ...data ]);
      } else {
        setData([ ...data.slice(1) ]);
      }
    }

    setEditingKey('');
  }

  const onRowSave = async (category) => {
    try {
      setLoading(true);

      const row = await form.validateFields();
      const payload = { parent_category_id: null, ...category, ...row };

      if (category.key === NEW_ROW_KEY) {
        if (category.parent_ref) {
          payload.parent_category_id = category.parent_ref.id;
          delete payload.parent_ref;
        }

        await onCreate(payload);
      } else {
        await onUpdate(payload);
      }

      setLoading(false);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const onExpandRow = (expanded, record) => {
    const keys = [...expandedRowKeys.filter(v => v !== record.key)];
    if (expanded) {
      keys.push(record.key);
    }

    setExpandedRowKeys(keys);
  }

  const createCategoryRow = (category = null) => {
    const record = {
      name: '',
      name_en: '',
      icon: '',
      key: NEW_ROW_KEY,
      parent_ref: category
    };

    if (category) {
      if (category.children) {
        category.children = [record, ...category.children];
      } else {
        category.children = [record];
      }

      setData([ ...data ]);
      onExpandRow(true, category);
    } else {
      setData([ record, ...data ]);
    }

    form.setFieldsValue({ ...record });
    setEditingKey(NEW_ROW_KEY);
  }

  const columns = [
    {
      title: t('categories.table.name_header'),
      dataIndex: 'name',
      key: 'name',
      editable: true,
      width: '35%'
    },
    {
      title: t('categories.table.name_en_header'),
      dataIndex: 'name_en',
      key: 'name_en',
      editable: true,
      width: '35%'
    },
    {
      // TODO: allow edit
      title: t('categories.table.icon_header'),
      dataIndex: 'icon',
      key: 'icon',
      editable: false,
      width: '10%'
    },
    {
      title: t('categories.table.action_header'),
      dataIndex: 'action',
      render: (_, record) => {
        const mode = isEditing(record) ? 'edit' : 'view';

        return <ActionsCell
          record={record}
          mode={mode}
          freeze={editingKey === ''}
          onEdit={onRowEdit}
          onEditReject={onRowEditReject}
          onSave={onRowSave}
          onDelete={onDelete}
          onAdd={createCategoryRow}
        />
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        title: col.title,
        dataIndex: col.dataIndex,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Button
        type="primary"
        style={{ marginBottom: 16 }}
        onClick={() => createCategoryRow()}
      >
          {t('categories.btns.add')}
      </Button>

      <Table
        bordered
        size='small'
        columns={mergedColumns}
        loading={loading}
        expandable={{
          onExpand: onExpandRow,
          expandedRowKeys
        }}
        // rowSelection={{ ...rowSelection, checkStrictly: false }}
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        dataSource={data}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};
