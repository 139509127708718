import { useEffect, useState } from 'react';
import { getCategories, updateCategory, deleteCategory, createCategory } from '../../services/DataService';
import { CategoriesTable } from '../../components';
import { buildCategoriesTree } from '../../utils';

export const Categories = () => {
    const [categories, setCategories] = useState([]);

    const fetchCategories = async () => {
        const categories = buildCategoriesTree(await getCategories(), v => ({ ...v, key: v.id }));
        setCategories(categories);
    };

    const onCreateCategory = async (payload) => {
        await createCategory(payload);
        await fetchCategories();
    }

    const onUpdateCategory = async (payload) => {
        await updateCategory(payload.id, payload);
        await fetchCategories();
    }

    const onDeleteCategory = async (payload) => {
        await deleteCategory(payload.id);
        await fetchCategories();
    }


    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <section className="section-container">
            <CategoriesTable
                categories={categories}
                onCreate={onCreateCategory}
                onUpdate={onUpdateCategory}
                onDelete={onDeleteCategory}
            />
        </section>
    );
};
