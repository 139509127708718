
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input, Select, TreeSelect } from 'antd';

import { buildCategoriesTree } from '../../../utils';

export const SuppliersForm = ({ formRef, record, locations, categories }) => {
    const { t } = useTranslation();
    const locationNameProp = t('common.t_fields.location_name');
    const categoryNameProp = t('common.t_fields.category_name');

    const categoriesTree = buildCategoriesTree(categories, v => ({ title: v[categoryNameProp], key: v.id, value: v.id }));

    useEffect(() => {
        const formData = { ...record };
        formData.location_ids = (formData.locations || []).map(v => v.id);
        formData.category_ids = (formData.categories || []).map(v => v.id);

        formRef.setFieldsValue({ ...formData })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record]);

    return (
        <Form form={formRef} layout="vertical">
            <Form.Item
                name="name"
                label={t('suppliers.fields.name')}
                rules={[{ required: true, message: t('common.form.required_field') }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                name="description"
                label={t('suppliers.fields.description')}
                rules={[{ required: true, message: t('common.form.required_field') }]}
            >
                <Input.TextArea rows={5}/>
            </Form.Item>

            <Form.Item
                name="contact"
                label={t('suppliers.fields.contact')}
                rules={[{ required: true, message: t('common.form.required_field') }]}
            >
                <Input.TextArea rows={5}/>
            </Form.Item>

            <Form.Item
                name="headquarters"
                label={t('suppliers.fields.headquarters')}
                rules={[{ required: true, message: t('common.form.required_field') }]}
            >
                <Input allowClear/>
            </Form.Item>


            <Form.Item
                name="location_ids"
                label={t('suppliers.fields.locations')}
                rules={[
                    {
                        required: true,
                        type: 'array',
                        min: 1,
                        message: t('common.form.required_field')
                    }
                ]}
            >
                <Select
                    name="location_ids"
                    mode="multiple"
                    style={{ width: '100%' }}
                    allowClear={true}
                >
                    {locations.map(v => <Select.Option key={v.id} value={v.id}>{v[locationNameProp]}</Select.Option>)}
                </Select>
            </Form.Item>

            <Form.Item
                name="category_ids"
                label={t('suppliers.fields.categories')}
                rules={[
                    {
                        required: true,
                        type: 'array',
                        min: 1,
                        message: t('common.form.required_field')
                    }
                ]}
            >
                <TreeSelect
                    name="category_ids"
                    mode="multiple"
                    style={{ width: '100%' }}
                    treeData={categoriesTree}
                    treeCheckable={true}
                    showCheckedStrategy={TreeSelect.SHOW_PARENT}
                    allowClear={true}/>
            </Form.Item>

        </Form>
    );
}
