import { useEffect, useState, useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { logout } from '../../services/AuthService';

import { ModalContext, LanguageSwitcher } from '../../components';

import './index.scss';

export const Navigation = () => {
  const { showLoader } = useContext(ModalContext);
  const [activeNav, setActiveNav] = useState('/volunteers');
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const logoutHandler = async (e) => {
    e.preventDefault();

    try {
      showLoader(true);
      await logout();
    } finally {
      history.push('/login');
      setTimeout(() => showLoader(false), 250);
    }
  };

  useEffect(() => {
    setActiveNav(location.pathname);
  }, [location.pathname]);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-primary mb-3">
      <div className="container">
        <Link className="navbar-brand" to="/" />
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="main-navbar-collapse collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className={`nav-link ${activeNav.indexOf('/volunteers') !== -1 && 'active'}`}
                to='/volunteers'
              >
                {t('main_nav.volunteers')}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${activeNav.indexOf('/suppliers') !== -1 && 'active'}`}
                to='/suppliers'
              >
                {t('main_nav.suppliers')}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${activeNav.indexOf('/categories') !== -1 && 'active'}`}
                to='/categories'
              >
                {t('main_nav.categories')}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${activeNav.indexOf('/users') !== -1 && 'active'}`}
                to='/users'
              >
                {t('main_nav.users')}
              </Link>
            </li>
          </ul>

          <div className="navbar-nav-right">
            <LanguageSwitcher />
            <button type="button" onClick={logoutHandler}>{t('main_nav.logout')}</button>
          </div>
        </div>
      </div>
    </nav>
  );
};
