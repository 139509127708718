import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { hot } from 'react-hot-loader';

import { Login, Volunteers, Categories, Suppliers } from './containers';
import { Layout } from './components';

export const App = withRouter(hot(module)(() => {
  const token = localStorage.getItem('token');
  const isAuthorized = token && token.length;

  return (
    <Switch>
      <Route path="/login" render={() => <Login />} />

      <Route
        exact
        path="/volunteers"
        render={() => (
          !isAuthorized
            ? <Redirect to="/login" />
            : <Layout children={<Volunteers />} />
        )}
      />

      <Route
        path="/suppliers"
        render={() => (
          !isAuthorized
            ? <Redirect to="/login" />
            : <Layout children={<Suppliers />} />
        )}
      />

      <Route
        path="/categories"
        render={() => (
          !isAuthorized
            ? <Redirect to="/login" />
            : <Layout children={<Categories />} />
        )}
      />

      <Route
        path="/users"
        render={() => (
          !isAuthorized
            ? <Redirect to="/login" />
            : <Layout children={<h2>Users Screen</h2>} />
        )}
      />

      <Route
        exact
        path="*"
        render={() => (<Redirect to={!isAuthorized ? '/login' : '/volunteers'} />)}
      />
    </Switch>
  );
}));
