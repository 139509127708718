import { useTranslation } from 'react-i18next';
import { Table } from 'antd';

import { StatusCell, TitleCell, EllipsisCell, TagsCell, ActionsCell } from '../../../components/TableCells';

export const VolunteersTable = ({ dataSource, onEdit, onDelete, onApprove, onReject }) => {
    const { t } = useTranslation();

    const columns = [
        {
            width: 75,
            fixed: 'left',
            title: t('volunteers.fields.status'),
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: (status) => <StatusCell status={status} />
        },
        {
            width: 200,
            fixed: 'left',
            title: t('volunteers.fields.name'),
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => <TitleCell {...record} />
        },
        {
            width: 200,
            title: t('volunteers.fields.description'),
            dataIndex: 'description',
            key: 'description',
            ellipsis: {
                showTitle: true
            },
            render: (v) => <EllipsisCell text={v} />
        },
        {
            width: 200,
            title: t('volunteers.fields.locations'),
            dataIndex: 'locations',
            key: 'locations',
            render: (locations) =>
                <TagsCell
                    tags={locations}
                    color="gray"
                    titleProp={t('common.t_fields.location_name')} />
        },
        {
            width: 200,
            title: t('volunteers.fields.categories'),
            dataIndex: 'categories',
            key: 'categories',
            render: (categories) =>
                <TagsCell
                    tags={categories}
                    color="gray"
                    titleProp={t('common.t_fields.category_name')} />
        },
        {
            width: 200,
            title: t('volunteers.fields.headquarters'),
            dataIndex: 'headquarters',
            key: 'headquarters'
        },
        {
            width: 200,
            title: t('volunteers.fields.contacts_for_admin'),
            dataIndex: 'contacts_for_admin',
            key: 'contacts_for_admin',
            render: (v) => <EllipsisCell text={v} />
        },
        {
            width: 200,
            title: t('volunteers.fields.contacts_for_donors'),
            dataIndex: 'contacts_for_donors',
            key: 'contacts_for_donors',
            render: (v) => <EllipsisCell text={v} />
        },

        {
            width: 75,
            fixed: 'right',
            title: t('volunteers.actions_header'),
            key: 'actions',
            align: 'center',
            render: (_, record) =>
                <ActionsCell
                    record={record}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onApprove={onApprove}
                    onReject={onReject} />
        }
    ];

    return (
        <Table
            size="small"
            columns={columns}
            dataSource={dataSource}
            scroll={{ x: columns.reduce((o, v) => o + v.width) }} />
    );
}
