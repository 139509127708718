import { Navigation } from '../Navigation';

import './index.scss';

export const Layout = function ({ children }) {
  return (
    <div className="content-container">
      <Navigation />

      <div className="custom-container">
        {children}
      </div>
    </div>
  );
};
