import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { login } from '../../services/AuthService';
import { LanguageSwitcher } from '../../components';

import { Row, Col, Layout, Form, Input, Button, message } from 'antd';

export const LoginForm = ({ onSubmit }) => {
  const { t } = useTranslation();

  return (
    <Form
      name="basic"
      layout="vertical"
      onFinish={onSubmit}
      autoComplete="off"
    >
      <Form.Item
        label={t('login.form.username_field')}
        name="username"
        rules={[
          {
            required: true,
            message: t('common.form.required_field')
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t('login.form.password_field')}
        name="password"
        rules={[
          {
            required: true,
            message: t('common.form.required_field'),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {t('login.form.submit_btn')}
        </Button>
      </Form.Item>
    </Form>
  );
};


export const Login = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const onSubmit = async (formData) => {
    try {
      await login(formData);
      history.push('/volunteers');
    } catch (error) {
      if (error?.response?.status === 400) {
        message.error(t('login.form.failed_message'));
        return;
      }

      message.error(error.message);
    }
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {/* <Header>Header</Header> */}
      <Layout>
        <Layout.Content>
          <Row align="middle" style={{ minHeight: '100vh' }}>
            <Col span={8} offset={8}>
              <LanguageSwitcher />

              <LoginForm onSubmit={onSubmit} />
            </Col>
          </Row>
        </Layout.Content>
      </Layout>
      {/* <Footer>Footer</Footer> */}
    </Layout>
  )
}
