export const makeUniqKey = (length = 10) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789#$@!&()';
  let result = '';
  for (let i = 0, len = characters.length; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * len));
  }
  return result;
}

export const emailValidator = (email = '') => String(email).toLowerCase().match(
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const isFunction = (f) => f && typeof f === 'function';


export const NONE_PARENT_CATEGORY_ID = 0;
export const buildCategoriesTree = (list, fn) => {
  const map = {};
  for (const category of list) {
    map[category.id] = { ...category, children: [] }
  }

  const tree = {};
  for (const category of list) {
    const mapRef = map[category.id];

    // top level elements
    if (category.parent_category_id === NONE_PARENT_CATEGORY_ID) {
      tree[category.id] = mapRef;

      continue;
    }

    // sub level elements
    if (category.parent_category_id in map) {
      map[category.parent_category_id].children.push(mapRef);

      continue;
    }

    // not found
    console.warn(`Category parent didn't found. [id=${category.id}, parent_category_id=${category.parent_category_id}]`);
  }

  if (typeof fn === 'function') {
    const mapRecursive = obj => {
      if (obj.children.length === 0) {
        return fn(obj);
      }

      return {
        ...fn(obj),
        children: obj.children.map(v => mapRecursive(v)),
      };
    }

    for (const key in tree) {
      tree[key] = mapRecursive(tree[key]);
    }
  }

  return Object.values(tree);
}
