import axios from 'axios';

export const apiAxios = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_URL : '/api/v1'
});

// Request interceptor for API calls
apiAxios.interceptors.request.use(
  async config => {
    const token = localStorage.getItem('token');
    if (token === undefined) {
      return config;
    }

    config.headers = { 'X-ACCESS-TOKEN': token };

    return config;
  },
  error => {
    Promise.reject(error)
  }
);

export const getVolunteers = async (status = '', query = '', locations = '', categories = '') => {
  const params = {};
  if (!!status) {
    params['status'] = status;
  }
  if (!!query) {
    params['query'] = query;
  }
  if (!!locations) {
    params['locations'] = locations;
  }
  if (!!categories) {
    params['categories'] = categories;
  }
  const { data } = await apiAxios.get('/volunteers', { params });
  return data;
};

export const getVolunteer = async (id) => {
  const { data } = await apiAxios.get(`/volunteers/${id}`);
  return data;
};

export const createVolunteer = async (formData) => {
  const { data } = await apiAxios.post(`/volunteers`, formData);
  return data;
};

export const updateVolunteer = async (id, formData) => {
  const { data } = await apiAxios.put(`/volunteers/${id}`, formData);
  return data;
};

export const deleteVolunteer = async (id) => {
  const { data } = await apiAxios.delete(`/volunteers/${id}`);
  return data;
};

export const approveVolunteer = async (id) => {
  const { data } = await apiAxios.put(`/volunteers/${id}/approve`);
  return data;
};

export const rejectVolunteer = async (id) => {
  const { data } = await apiAxios.put(`/volunteers/${id}/reject`);
  return data;
};

export const getSuppliers = async (status = '', query = '', locations = '', categories = '') => {
  const params = {};
  if (!!status) {
    params['status'] = status;
  }
  if (!!query) {
    params['query'] = query;
  }
  if (!!locations) {
    params['locations'] = locations;
  }
  if (!!categories) {
    params['categories'] = categories;
  }
  const { data } = await apiAxios.get('/suppliers', { params });
  return data;
};

export const getSupplier = async (id) => {
  const { data } = await apiAxios.get(`/suppliers/${id}`);
  return data;
};

export const createSupplier = async (formData) => {
  const { data } = await apiAxios.post(`/suppliers`, formData);
  return data;
};

export const updateSupplier = async (id, formData) => {
  const { data } = await apiAxios.put(`/suppliers/${id}`, formData);
  return data;
};

export const deleteSupplier = async (id) => {
  const { data } = await apiAxios.delete(`/suppliers/${id}`);
  return data;
};

export const approveSupplier = async (id) => {
  const { data } = await apiAxios.put(`/suppliers/${id}/approve`);
  return data;
};

export const rejectSupplier = async (id) => {
  const { data } = await apiAxios.put(`/suppliers/${id}/reject`);
  return data;
};

export const getCategories = async () => {
  const { data } = await apiAxios.get('/categories');
  return data;
};

export const createCategory = async (payload) => {
  const { data } = await apiAxios.post(`/categories`, { ...payload });
  return data;
};

export const updateCategory = async (id, payload) => {
  const { data } = await apiAxios.put(`/categories/${id}`, { ...payload });
  return data;
};

export const deleteCategory = async (id) => {
  const { data } = await apiAxios.delete(`/categories/${id}`);
  return data;
};

export const getLocations = async () => {
  const { data } = await apiAxios.get('/locations');
  return data;
};
