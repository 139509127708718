import { useContext, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { ModalContext } from './modalContext';

import './index.scss';

const loaderRoot = document.getElementById('loader-root');

export const Loader = () => {
  const { loader } = useContext(ModalContext);
  const domElement = useMemo(() => document.createElement('div'), []);

  useEffect(() => {
    loaderRoot.appendChild(domElement);

    return () => {
      loaderRoot.removeChild(domElement);
    };
  }, [domElement]);

  if (!loader) {
    document.getElementById('body').classList.remove('no-scrolable');
    return null;
  }

  document.getElementById('body').classList.add('no-scrolable');

  return createPortal(
    <div className="loader-backdrop">
      <div className="spinner-border" role="status"></div>
    </div>,
    domElement
  );
};
