import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isFunction, makeUniqKey } from '../../utils';

import './index.scss';

const commonId = `text-filter-field-${makeUniqKey()}`;

export const TextFilterField = ({
  domId, domClasses, domLabel, defaultOption, selected, options, onChange,
}) => {
  const [value, setValue] = useState(selected || options[0].value);
  const list = Array.isArray(options) ? options : [];
  const { t } = useTranslation();

  const handleChange = (e) => {
    e.preventDefault();
    setValue(e.target.value);
  };

  return (
    <div className="text-filter-field-wrap">
      <div className="form-floating">
        <select
          className={`form-select ${!!domClasses ? domClasses : ''}`}
          placeholder="..."
          id={domId || commonId}
          name={domId || commonId}
          onChange={isFunction(onChange) ? onChange : handleChange}
          value={selected || value}
        >
          {!!defaultOption && <option>{defaultOption}</option>}
          {list.length &&
            list.map(el => (
              <option key={el.id} value={el.value}>{el.label}</option>
          ))}
        </select>
        <label htmlFor={domId || commonId}>{domLabel || t('common.form.filter_label')}</label>
      </div>
    </div>
  );
};
