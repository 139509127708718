import { useTranslation } from 'react-i18next';
import { Tag, Menu, Dropdown, Button, Space, Tooltip } from 'antd';
import {
    MoreOutlined, EditOutlined, DeleteOutlined, CheckOutlined, CloseOutlined,
    CheckCircleOutlined, CloseCircleOutlined, SyncOutlined, LinkOutlined
} from '@ant-design/icons';

export const EllipsisCell = ({ text, placement = "topLeft" }) => (
    <Tooltip placement={placement} title={text}>
        {text}
    </Tooltip>
)

export const StatusCell = ({ status }) => {
    const style = { fontSize: '14pt' };

    switch (status) {
        case 'approved':
            return <CheckCircleOutlined style={{ ...style, color: '#52c41a' }} />;
        case 'rejected':
            return <CloseCircleOutlined style={{ ...style, color: '#ff4d4f' }} />;
        case 'pending':
            return <SyncOutlined style={{ ...style, color: '#1890ff' }} />;
        default:
            return null;
    }
}

export const TitleCell = ({ name, site }) => (
    <>
        {name}
        {site && (
            <Button
                type="link"
                size="small"
                style={{ verticalAlign: 'text-bottom' }}
                icon={<LinkOutlined />}
                href={site}
                title="Visit website"/>
        )}
    </>
)

export const TagsCell = ({ tags, color, keyProp = 'id', titleProp = 'name' }) => (
    <Space size={[0, 5]} wrap>
        {tags.map(v => (
            <Tag color={color} key={v[keyProp]}>
                {v[titleProp]}
            </Tag>
        ))}
    </Space>
);

export const ActionsCell = ({ record, onEdit, onDelete, onApprove, onReject }) => {
    const { t } = useTranslation();
    const { key, status } = record;

    const items = [
        {
            key: `${key}-edit`,
            title: t('common.actions.edit'),
            icon: <EditOutlined />,
            onClick: () => onEdit(record),
        },
        {
            key: `${key}-delete`,
            title: t('common.actions.delete'),
            icon: <DeleteOutlined />,
            onClick: () => onDelete(record),
        },
        {
            render: () => <Menu.Divider key={`${key}-divider-1`}/>
        }
    ];

    if (status === 'pending' || status === 'rejected') {
        items.push({
            key: `${key}-approve`,
            title: t('common.actions.approve'),
            icon: <CheckOutlined />,
            onClick: () => onApprove(record),
        });
    }

    if (status === 'pending' || status === 'approved') {
        items.push({
            key: `${key}-reject`,
            title: t('common.actions.reject'),
            icon: <CloseOutlined />,
            onClick: () => onReject(record),
        });
    }

    return (
        <Dropdown
            overlay={
                <Menu>
                    {items.map(item => {
                        if (item.render) {
                            return item.render();
                        }

                        return (
                            <Menu.Item key={item.key} icon={item.icon} onClick={item.onClick}>
                                {item.title}
                            </Menu.Item>
                        )
                    })}
                </Menu>
            }
            trigger={['click']}
        >
            <Button type="link" icon={<MoreOutlined style={{ color: '#000' }} />} />
        </Dropdown>
    )
}
