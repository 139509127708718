import { createContext } from 'react';

import { Modal } from '../Modal';

import { Loader } from './Loader';
import useModal from './useModal';
import useLoader from './useLoader';

let ModalContext;
const { Provider } = (ModalContext = createContext());

const ModalProvider = ({ children }) => {
  const { modal, handleModal, modalContent, modalTitle, modalFooter, className } = useModal();
  const { loader, showLoader } = useLoader();

  return (
    <Provider value={{
      modal, handleModal, modalContent, modalTitle, modalFooter, className, loader, showLoader,
    }}>
      <Modal />
      <Loader />
      {children}
    </Provider>
  );
};

export {
  ModalContext,
  ModalProvider,
};
