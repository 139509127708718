import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_LANGUAGE } from '../../constants';

import './index.scss';

export const LanguageSwitcher = () => {
  const [currentLang, setLanguage] = useState(localStorage.getItem('language') || DEFAULT_LANGUAGE);
  const { i18n } = useTranslation();

  const handleClick = (e) => {
    e.preventDefault();
    setLanguage(e.target.value);
    localStorage.setItem('language', e.target.value);
  };

  useEffect(() => {
    i18n.changeLanguage(currentLang);
  }, [currentLang, i18n]);

  return (
    <div className="language-control-wrap">
      <button
        className={`lang-btn ${currentLang === 'en' ? 'active' : ''}`}
        onClick={handleClick}
        value="en">
        EN
      </button>
      <button
        className={`lang-btn ${currentLang === 'ua' ? 'active' : ''}`}
        onClick={handleClick}
        value="ua">
        UA
      </button>
    </div>
  )
};
