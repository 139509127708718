
import { useTranslation } from 'react-i18next';
import { Space, Button } from 'antd';

export const CustomDrawerControls = ({ onClose, onSubmit }) => {
    const { t } = useTranslation();

    return (
        <Space>
            <Button onClick={onClose}>
                {t('common.actions.close')}
            </Button>
            <Button onClick={onSubmit} type="primary">
                {t('common.actions.save')}
            </Button>
        </Space>
    );
}
