
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row, Col, Space, Card,
    Form, Input, Select, TreeSelect, Button, Divider, Switch
} from 'antd';
import { PlusOutlined, MinusCircleOutlined, DeleteOutlined } from '@ant-design/icons';

import { buildCategoriesTree } from '../../../utils';

export const CategoriesDistribution = ({ categoriesTree }) => {
    const { t } = useTranslation();

    const deleteAllFields = (fields, { remove }) => {
        // NOTE: (reverse required to start removing from the end)
        fields.reverse().forEach(field => {
            remove(field.name)
        });
    }

    const validateCards = async (_, cards) => {
        const hash = {};
        cards.forEach((card, index) => {
            if (!card?.category_ids?.length) {
                return;
            }

            card.category_ids.forEach(id => {
                if (hash[id]) {
                    hash[id].push(index);
                } else {
                    hash[id] = [index];
                }
            });
        });


        let isError = Object.values(hash).some(arr => arr.length > 1);

        if (isError) {
            return Promise.reject(t('volunteers.errors.categories_settings'))
        }
    }

    return (
        <Form.List
            name="settings.categories_contacts"
            rules={[{ validator: validateCards }]}
        >
            {(fields, { add, remove }, { errors }) => (
                <>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.ErrorList errors={errors} />
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]} style={{ marginTop: '10px' }}>
                        {
                            fields.map(({ key, name, ...props }) => (
                                <Col span={6} key={key}>
                                    <Card
                                        bordered
                                        title={<MinusCircleOutlined onClick={() => remove(name)} />}
                                        style={{ height: '100%' }}
                                    >
                                        <Form.Item
                                            {...props}
                                            name={[name, 'category_ids']}
                                            rules={[{ required: true, message: t('common.form.required_field') }]}
                                            label={t('volunteers.fields.s_categories')}
                                        >
                                            <TreeSelect
                                                showSearch
                                                allowClear
                                                mode="multiple"
                                                style={{ width: '100%' }}
                                                placeholder="..."
                                                treeData={categoriesTree}
                                                treeCheckable={true}
                                                showCheckedStrategy={TreeSelect.SHOW_PARENT} />
                                        </Form.Item>

                                        <Form.Item
                                            {...props}
                                            name={[name, 'contact']}
                                            rules={[{ required: true, message: t('common.form.required_field') }]}
                                            label={t('volunteers.fields.s_contact')}
                                        >
                                            <Input placeholder="..." />
                                        </Form.Item>

                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>

                    <Row gutter={16} style={{ marginTop: '10px' }}>
                        <Col span={fields.length ? 20 : 24}>
                            <Form.Item>
                                <Button
                                    block
                                    type="primary"
                                    onClick={() => add()}
                                    icon={<PlusOutlined />}
                                >
                                    {t('common.actions.create_new')}
                                </Button>
                            </Form.Item>
                        </Col>

                        {fields.length > 0 && (
                            <Col span={4}>
                                <Form.Item>
                                    <Button
                                        block
                                        danger
                                        type="primary"
                                        onClick={() => deleteAllFields(fields, { remove })}
                                        icon={<DeleteOutlined />}
                                    >
                                        {t('common.actions.delete_all')}
                                    </Button>
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                </>
            )}
        </Form.List>
    )
}

export const VolunteersForm = ({ formRef, record, locations, categories }) => {
    const { t } = useTranslation();
    const [useCategoriesContacts, switchCategoriesContacts] = useState(false);

    const locationNameProp = t('common.t_fields.location_name');
    const categoryNameProp = t('common.t_fields.category_name');
    const categoriesTree = buildCategoriesTree(categories, v => ({ title: v[categoryNameProp], key: v.id, value: v.id }));

    useEffect(() => {
        const formData = { ...record };
        formData.location_ids = (formData.locations || []).map(v => v.id);
        formData.category_ids = (formData.categories || []).map(v => v.id);
        try {
            const settings = JSON.parse(formData.settings);
            formData['settings.categories_contacts'] = settings?.categories_contacts;
        } catch {}

        formRef.setFieldsValue({ ...formData });
        switchCategoriesContacts(!!formData['settings.categories_contacts']);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [record]);

    return (
        <Form form={formRef} layout="vertical" validateTrigger={false}>
            <Divider plain>
                {t('volunteers.form_categories_settings_title')}
            </Divider>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="name"
                        label={t('volunteers.fields.name')}
                        rules={[{ required: true, message: t('common.form.required_field') }]}
                    >
                        <Input placeholder='...'/>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name="site"
                        label={t('volunteers.fields.site')}
                    >
                        <Input placeholder='...' />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="description"
                        label={t('volunteers.fields.description')}
                        rules={[{ required: true, message: t('common.form.required_field') }]}
                    >
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="location_ids"
                        label={t('volunteers.fields.locations')}
                        rules={[
                            {
                                required: true,
                                type: 'array',
                                min: 1,
                                message: t('common.form.required_field')
                            }
                        ]}
                    >
                        <Select
                            allowClear
                            showSearch
                            placeholder='...'
                            mode="multiple"
                            style={{ width: '100%' }}
                            filterOption={
                                (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {locations.map(v => <Select.Option key={v.id} value={v.id}>{v[locationNameProp]}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name="category_ids"
                        label={t('volunteers.fields.categories')}
                        rules={[
                            {
                                required: true,
                                type: 'array',
                                min: 1,
                                message: t('common.form.required_field')
                            }
                        ]}
                    >
                        <TreeSelect
                            showSearch
                            placeholder='...'
                            mode="multiple"
                            style={{ width: '100%' }}
                            treeData={categoriesTree}
                            treeCheckable={true}
                            showCheckedStrategy={TreeSelect.SHOW_PARENT}
                            filterTreeNode={
                                (input, node) => node.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            } />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="headquarters"
                        label={t('volunteers.fields.headquarters')}
                        rules={[{ required: true, message: t('common.form.required_field') }]}
                    >
                        <Input placeholder='...'/>
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={16}>
                <Col span={12}>
                    <Form.Item
                        name="contacts_for_admin"
                        label={t('volunteers.fields.contacts_for_admin')}
                        rules={[{ required: true, message: t('common.form.required_field') }]}
                    >
                        <Input.TextArea rows={4} placeholder='...'/>
                    </Form.Item>
                </Col>

                <Col span={12}>
                    <Form.Item
                        name="contacts_for_donors"
                        label={t('volunteers.fields.contacts_for_donors')}
                        rules={[{ required: true, message: t('common.form.required_field') }]}
                    >
                        <Input.TextArea rows={4} placeholder='...'/>
                    </Form.Item>
                </Col>
            </Row>

            <Divider plain>
                <Space>
                    {t('volunteers.form_categories_settings_title')}

                    <Switch
                        checked={useCategoriesContacts}
                        onChange={checked => switchCategoriesContacts(checked)} />
                </Space>
            </Divider>

            {useCategoriesContacts && (<CategoriesDistribution categoriesTree={categoriesTree}/>)}
        </Form>
    );
}
