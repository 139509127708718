import { apiAxios } from './DataService';

export const login = async (formData) => {
  const { data } = await apiAxios.post('/login', formData);

  localStorage.setItem('token', data.token);

  return true;
};

export const logout = async () => {
  localStorage.removeItem('token');
};
