import { useState } from 'react';

const useModal = () => {
  const [modal, setModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalFooter, setModalFooter] = useState(null);
  const [modalTitle, setModalTitle] = useState('');
  const [className, setModalClassName] = useState('');

  const handleModal = (content = null, title = '', footer = null, className = '') => {
    setModal(!modal);

    if (content) {
      setModalContent(content);
    }
    if (title) {
      setModalTitle(title);
    }
    if (footer) {
      setModalFooter(footer);
    }
    if (className) {
      setModalClassName(className);
    }
  };

  return {
    modal,
    handleModal,
    modalContent,
    modalFooter,
    modalTitle,
    className,
  };
};

export default useModal;
