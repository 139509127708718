// import { StrictMode } from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import 'antd/dist/antd.min.css';

import { ModalProvider } from './app/components';
import { App } from './app';
import reportWebVitals from './reportWebVitals';

import './i18n/config';
import './index.scss';

render(
  (
    // <StrictMode>
    <Router>
      <ModalProvider>
        <App />
      </ModalProvider>
    </Router>
    // </StrictMode>
  ), document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
