import { useContext, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';

import { ModalContext } from './modalContext';

import './index.scss';

const modalRoot = document.getElementById('modal-root');

export const Modal = () => {
  const {
    modalTitle, modalContent, modalFooter, handleModal, modal, className,
  } = useContext(ModalContext);
  const domElement = useMemo(() => document.createElement('div'), []);
  const { t } = useTranslation();

  useEffect(() => {
    modalRoot.appendChild(domElement);
    return () => {
      modalRoot.removeChild(domElement);
    };
  }, [domElement]);

  if (!modal) {
    document.getElementById('body').classList.remove('no-scrolable');
    return null;
  }

  document.getElementById('body').classList.add('no-scrolable');

  return createPortal(
    <div
      className={`modal fade ${modal && 'show'} ${className}`}
      id="modal-backdrop"
      style={{display: modal ? 'block' : 'none'}}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="modal-backdrop-label"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="modal-backdrop-label">{modalTitle || ''}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleModal}
            ></button>
          </div>

          <div className="modal-body">
            {modalContent}
          </div>

          {modalFooter ? (
            <>{modalFooter}</>
          ) : (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={handleModal}
              >
                {t('common.modal.close_btn')}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>,
    domElement
  );
};
